import type { AsyncNamespace, OttoNexus } from '@otto-ec/global-resources/nexus'
import { NEXUS_CMP_NAMESPACE } from './nexus-cmp-namespace.js'
import type { NexusCmp } from './nexus-cmp.type.js'

export type { NexusCmp } from './nexus-cmp.type.js'
export { NEXUS_CMP_NAMESPACE } from './nexus-cmp-namespace.js'

declare global {
  /*                                   */
  interface NexusInterfaces {
    /**
 *
 */
    [NEXUS_CMP_NAMESPACE]: AsyncNamespace<NexusCmp>
  }
}

/**
 *
 */
export type Cmp = OttoNexus[typeof NEXUS_CMP_NAMESPACE]

/**
 *
 */
export const cmp: Cmp = otto[NEXUS_CMP_NAMESPACE]

/*               */
export type * from './nexus-cmp.type.js'

/*                                 */
export const {
  allowAll,
  rejectAll,
  togglePreferenceCenter,
  rejectGeneralVendorConsent,
  getConsentForGV,
  getConsentsForAllGVs,
  getIabConsentString,
  readVendorsConsents,
  archiveReceipt,
  emitConsentChangeEvent,
  getOttoOwnPurposesConsent,
  getAppGoogleTrackingConsent,
  getAdjustTrackingConsent,
  processAppConsentV2,
  consentChanged,
} = otto.cmp
