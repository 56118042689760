export interface AppsBridge {
  runningInApp(): boolean

  cmp: {
    openPreferenceCenter(): void
    optOutAll(): void
    acceptConsents(): void
    optOutGeneralVendor(vendorId: string): void
    optOutIabVendor(vendorId: string): void
    optOutGoogleVendor(vendorId: string): void
  }
}

declare global {
  interface Window {
    o_apps: AppsBridge
  }
}
export class AppsAdapter {
  runningInApp(): boolean {
    return this.#apps?.runningInApp() ?? false
  }

  openPreferenceCenter(): void {
    this.#apps?.cmp.openPreferenceCenter()
  }
  optOutAll(): void {
    this.#apps?.cmp.optOutAll()
  }
  acceptConsents(): void {
    this.#apps?.cmp.acceptConsents()
  }
  optOutGeneralVendor(vendorId: string): void {
    this.#apps?.cmp.optOutGeneralVendor(vendorId)
  }
  optOutIabVendor(vendorId: string): void {
    this.#apps?.cmp.optOutIabVendor(vendorId)
  }
  optOutGoogleVendor(vendorId: string): void {
    this.#apps?.cmp.optOutGoogleVendor(vendorId)
  }

  get #apps(): AppsBridge | undefined {
    return window.o_apps ?? undefined
  }
}
